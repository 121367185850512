<template>
  <div class="upload-file">
    <el-upload
      action="/imgUpdata"
      :before-upload="handleBeforeUpload"
      :file-list="fileList"
      :limit="limit"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      :on-success="handleUploadSuccess"
      :on-remove="handleRemove"
      :show-file-list="false"
      :on-preview="handlePreview"
      class="upload-file-uploader"
      ref="upload"
      :multiple="true"
      :on-change="handleFileChange"
      :headers="{token}"
    >
      <!-- 上传按钮 -->
      <el-button size="mini" type="primary">{{ fileBtnText }}</el-button>
      <!-- 上传提示 -->
      <div class="el-upload__tip" slot="tip" v-if="showTip">
        请上传
        <template v-if="fileSize">
          大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b>
        </template>
        <template v-if="fileTypeOffer">
          格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b>
        </template>
        的文件
      </div>
    </el-upload>

    <!-- 文件列表 -->
    <transition-group
      class="upload-file-list el-upload-list el-upload-list--text"
      name="el-fade-in-linear"
      tag="ul"
      v-if="fileList.length"
    >
      <li
        :key="index"
        class="el-upload-list__item ele-upload-list__item-content"
        v-for="(file, index) in fileList"
      >
        <!-- <el-link href="" :underline="false"> -->
        <div>
          <span class="el-icon-document"> {{ file.name }} </span>
        </div>          
        <!-- </el-link> -->
        <div class="ele-upload-list__item-content-action">
          <el-popover placement="top" trigger="click" v-show="fileFormat(file)">
            <div class="demo-image__preview" v-if="imagePreview">
              <el-image
                style="width: 100px; height: 100px"
                :src="imgUrl"
                :preview-src-list="[imgUrl]"
                :z-index="999999"
              >
              </el-image>
            </div>
            <el-link
              :underline="false"
              v-show="fileFormat(file)"
              @click="handlepreviewPictures(file)"
              slot="reference"
              >预览图片</el-link
            >
          </el-popover>
          <el-link
            :underline="false"
            v-show="!file.raw"
            @click="handleFileDownload(file)"
            >下载</el-link
          >
          <el-link :underline="false" @click="handleDelete(index)" type="danger"
            >删除</el-link
          >
        </div>
      </li>
    </transition-group>
  </div>
</template>

<script>

// import { downloadInfo } from '../../utils/ruoyi'
export default {
  name: "fileUpload",
  props: {
    upFileKey: {
      type: String,
      default: 'file'
    },
    valueType: {
      type: String,
      default: 'array'
    },
    fileRealTimeUp: {
      type: Boolean,
      default: true
    },
    // 值
    value: [String, Object, Array],
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 10,
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => [
        "jpg",
        "jpeg",
        "png",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "pdf",
      ],
    },
    // 是否显示文件类型
    fileTypeOffer: {
      type: Boolean,
      default: false,
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true,
    },
    // 上传文件数
    limit: {
      type: Number,
      default: 5,
    },
    // 是否做文件格式校验。
    fileTypeIsTypeOk: {
      type: Boolean,
      default: true,
    },
    // 上传文件按钮文字
    fileBtnText: {
      type: String,
      default: "附件上传",
    },
    // 修改附件列表
    fileListModify: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 状态
    modifeText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API,
      // uploadFileUrl: "/imgUpdata", // 上传的图片服务器地址
      uploadFileUrl: "/fileops/upload", // 上传的图片服务器地址
      token: '',
      fileList: [],
      changeOffer: false,
      imgUrl: "",
      imagePreview: false,
    };
  },
  watch: {
    fileListModify: {
      handler: function(newVal, oldVal) {
        // 每次组件form值改变都会调接口
        this.fileList = newVal;
      },
      // immediate: true,
      deep: true
    },
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
    // 列表
    list() {
      let temp = 1;
      if (this.value) {
        // 首先将值转为数组
        const list = Array.isArray(this.value) ? this.value : [this.value];
        // 然后将数组转为对象数组
        return list.map((item) => {
          if (typeof item === "string") {
            item = { name: item, url: item };
          }
          item.uid = item.uid || new Date().getTime() + temp++;
          return item;
        });
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.fileList = this.fileListModify;
    this.token = JSON.parse(window.localStorage.getItem('token'));
  },
  methods: {
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      // 校检文件类型
      if (this.fileTypeIsTypeOk) {
        if (this.fileType) {
          let fileExtension = "";
          if (file.name.lastIndexOf(".") > -1) {
            fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
          }
          const isTypeOk = this.fileType.some((type) => {
            if (file.type.indexOf(type) > -1) return true;
            if (fileExtension && fileExtension.indexOf(type) > -1) return true;
            return false;
          });
          if (!isTypeOk) {
            this.$message.error(
              `文件格式不正确, 请上传格式为 ${this.fileType.join(
                "/"
              )} 格式文件!`
            );
            return false;
          }
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      return true;
    },
    // 文件个数超出
    handleExceed() {
      this.$message.error(`只允许上传${this.limit}个文件`);
    },
    // 上传失败
    handleUploadError(err) {
      this.$message.error("上传失败, 请重试");
    },
    // 上传成功回调
    handleUploadSuccess(res, file, list) {
      this.$message.success("上传成功");
      // //console.log('1111111', res, file, list)
      const { fileList } = this;
      let dataList = [...fileList];

      // 确保每一张照片都上传完成。
      let offer = dataList.every(item => item.status === 'success');
      if (offer) {
        this.$emit("getFileListAndForm", { fileList: dataList, key: this.upFileKey, valueType: this.valueType });
      }
    },
    // 提交
    submitUpload() {

      // if (this.changeOffer && this.fileRealTimeUp) {
      //   this.$refs.upload.submit();
      //   return;
      // }

      // if (this.modifeText === "modify") {
      // //console.log(this.fileList)
      this.$emit("getFileListAndForm", { fileList: this.fileList, key: this.upFileKey, valueType: this.valueType }, true);
      // } else {
      // this.$emit("getFileListAndForm");
      // }
    },
    // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    handleFileChange(file, fileList) {
      this.fileList = fileList;
      this.$emit('fileListChange', fileList)
      this.changeOffer = true;
    },
    // 文件列表被移除文件时的钩子
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    // 点击文件列表中已上传的文件钩子(下载)
    handlePreview(file) {
      if(file.url) {        
        window.location.href =window.location.origin + "/" + encodeURI(file.url);
      }
    },
    // 删除文件
    handleDelete(index) {
      this.fileList.splice(index, 1);
    },
    // 下载文件
    handleFileDownload(item) {
      if(item.blob && item.fileData){
        this.ways(item.blob, item.fileData, item.name);
      }
    },

    ways(blob, suffix, name) {
      let elink = document.createElement("a");   // 创建一个<a>标签
      elink.style.display = "none";                       // 隐藏标签
      elink.href = window.URL.createObjectURL(blob);      // 配置href
      //自定义名称
      let newFilename = name; //自定义名字
      // let newFilename = decodeURIComponent(res.headers["content-disposition"].split(';')[1].split('=')[1])
      newFilename = decodeURIComponent(newFilename);
      elink.download = newFilename;
      elink.click();
      URL.revokeObjectURL(elink.href);   // 释放URL 对象(弹出框进行下载)
      document.body.removeChild(elink);  // 移除<a>标签
    },
    
    // 图片预览
    async handlepreviewPictures(item) {

      let url = "";
      let imgOffer = false;
      // 添加未上传图片解析预览。
      if (item && item.raw) {
        let previewSrc = await this.createFileUrl(item);
        url = previewSrc;
        imgOffer = true;
      } else {

        // const baseURL = process.env.VUE_APP_BASE_API;
        url = item.url
        imgOffer = true;
      }
      this.imgUrl = url;
       this.imagePreview = imgOffer;
    },
    // 图片解析路径, 解析raw
    createFileUrl(file) {

      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.readAsDataURL(file.raw);
        fr.onload = function () {
          resolve(fr.result);
        };

      });
    },
    // 格式
    fileFormat(item) {
      let format = ["png", "jpg", "jpeg"];
      let fileExtension = "";
      // 上传成功解析
      if (item.fileName) {
        if (item.fileName.lastIndexOf(".") > -1) {
          fileExtension = item.fileName.slice(
            item.fileName.lastIndexOf(".") + 1
          );
        }
      }
      // 添加未上传解析
      if (item.name && item.raw) {
        if (item.name.lastIndexOf(".") > -1) {
          fileExtension = item.name.slice(item.name.lastIndexOf(".") + 1);
        }
      }

      // 修改
      if (item.name && this.fileListModify.length > 0) {
        if (item.name.lastIndexOf(".") > -1) {
          fileExtension = item.name.slice(item.name.lastIndexOf(".") + 1);
        }
      }
      const isTypeOk = format.some((type) => {
        if (fileExtension && format.includes(fileExtension)) {
          return true;
        }
        return false;
      });
      return isTypeOk;
    },
    // 获取文件名称
    getFileName(name) {
      if (name.lastIndexOf("/") > -1) {
        return name.slice(name.lastIndexOf("/") + 1).toLowerCase();
      } else {
        return "";
      }
    },
  },
  // created() {
  //   this.fileList = this.list;
  // },
};
</script>

<style scoped lang="scss">
.upload-file {
  /* width: 50%; */
  border: 1px solid #c0c4cc;
  padding: 10px;
  padding-right: 20%;
  margin-bottom: 10px;
}
.upload-file-uploader {
  margin-bottom: 5px;
}
.upload-file-list .el-upload-list__item {
  border: 1px solid #e4e7ed;
  line-height: 1;
  margin-bottom: 10px;
  position: relative;
  padding: 5px
}
.upload-file-list .ele-upload-list__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
}
.ele-upload-list__item-content-action{
  min-width: 110px;
  margin-left: 10px;
}
.ele-upload-list__item-content-action .el-link {
  margin-right: 10px;
}
</style>
