<template>
  <div class="tableBox">
    <div class="title">
      <div class="ctrolbtnBox" v-if="addBtn">
        <el-button type="primary" size="small" @click="$emit('addOption')">新增</el-button>
      </div>
      <div class="searchBox"></div>
    </div>
    <el-table v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark"
      style="width: 100%; margin-top: 1vh; table-layout: fixed;">
      <el-table-column
        label="序号"
        type="index"
        width="50">
      </el-table-column>
      <el-table-column v-for="item in tableHeader" :key="item.key" :prop="item.key" :label="item.label"
        show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <div v-if="item.render">
            <expandDom :column="item" :row="scope.row" :render="item.render" :index="scope.$index" :prop="item.prop">
            </expandDom>
          </div>
          <div v-else>{{ scope.row[item.key] }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" min-width="100" v-if="rowBtns.length">
        <template slot-scope="scope">
          <el-button v-for="(m, mindex) in rowBtns" :key="mindex" :type="m.type" :size="m.size" :icon="m.icon"
            @click="m.handler(scope.row)">{{ m.label }}
          </el-button>

          <el-button v-if="scope.row.pushRowsBtn.length > 0" v-for="(m, mindex) in scope.row.pushRowsBtn" :key="mindex"
            :type="m.type" :size="m.size" @click="m.handler(scope.row)">{{ m.label }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox" v-if="isPaging">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
        :page-sizes="[10, 20, 30, 40]" :page-size="size" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    /**
     * render函数渲染组件
     * */
    expandDom: {
      functional: true,
      props: {
        row: Object,
        prop: String,
        render: Function,
        index: Number,
        column: {
          type: Object,
          default: null,
        },
      },
      render: (h, ctx) => {
        const params = {
          row: ctx.props.row,
          index: ctx.props.index,
          column: ctx.props.column,
          prop: ctx.props.prop,
          text: ctx.props.row[ctx.props.prop],
        };

        if (ctx.props.column) params.column = ctx.props.column;
        return ctx.props.render(h, params);
      },
    },
  },
  props: {
    tableHeader: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tableParams: {
      type: Object,
      default: () => {
        return {};
      },
    },
    rowBtns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    request: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    disposeData: {
      type: Boolean,
      default: false,
    },
    isPaging: {
      type: Boolean,
      default: true,
    },
    addBtn:  {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      page: 0,
      size: 20,
      total: 0,
      loading: false,
      tableData: [],
    };
  },
  watch: {},
  mounted() {
    this.getList();
  },
  methods: {
    //请求列表数据
    getList() {
      let params = {}
      if (this.isPaging) {
        params = {
          page: this.page,
          size: this.size,
        }
      } else {
        params = this.tableParams
      }
      this.$get(this.request, params).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.data;
          if (this.isPaging) {
            this.total = res.data.size;
            if (this.tableData.length == 0 && this.page > 0) {
              this.page = 0;
              this.getList();
            }
            if (this.tableData.length > 0 && this.disposeData) {
              this.setSpericalData(this.tableData);
            }
          } 

        }
      });
    },

    // 根据值不同处理显示
    // 这里需要根据场景需要自行修改
    setSpericalData(data) {

      data.forEach((item) => {
        if (this.name == "expertMess") { // 专家信息
          if (item.status == 0) {
            item.pushRowsBtn = [
              {
                label: "在线",
                type: "primary",
                size: "small",
                handler: (row) => {
                  this.$emit("otherHandler", "online", row);
                },
              },
            ];
          } else if (item.status == 1) {
            item.pushRowsBtn = [
              {
                label: "离线",
                type: "error",
                size: "small",
                handler: (row) => {
                  this.$emit("otherHandler", "offline", row);
                },
              },
            ];
          }
        }
      });
    },

    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },

    refreshTable() {
      this.page = 0;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.tableBox /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

.tableBox /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

.tableBox ::v-deep .el-table::-webkit-scrollbar {
  display: none;
}

.tableBox ::v-deep .el-table .el-table__cell.is-hidden>* {
  visibility: visible !important;
}

.title ::v-deep .el-input__inner {
  height: 33px !important;

}

.title {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  .ctrolbtnBox {
    width: 30%;
    display: flex;
    flex-flow: row;
    //  justify-content: flex-end;
  }
}

.pagingBox {
  width: 100%;
  padding: 30px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

}
</style>